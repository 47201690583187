import { ConfigProvider, Steps, StepsProps } from "antd";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const Roadmap = () => {
    const { t } = useTranslation()
    const [currentQuarter, setCurrentQuarter] = useState<number>(1)
    const getCurrentQuarter = useCallback(() => {
        const date = new Date();  // 获取当前日期
        const month = date.getMonth();  // 获取当前月份 (0-11)
        // 判断当前月份属于哪个季度
        if (month >= 0 && month <= 2) {
            return 0;  // 第一季度 (1月-3月)
        } else if (month >= 3 && month <= 5) {
            return 1;  // 第二季度 (4月-6月)
        } else if (month >= 6 && month <= 8) {
            return 2;  // 第三季度 (7月-9月)
        } else {
            return 3;  // 第四季度 (10月-12月)
        }
    }, [])
    const data = [
        { title: <div onClick={() => { setCurrentQuarter(0) }} className={`text-2xl mb-7 max-md:text-base ${currentQuarter === 0 ? 'text-white' : ''}  `}>Q1 2024</div>, description: <div onClick={() => { setCurrentQuarter(0) }} className={` ${currentQuarter === 0 ? 'text-white' : ''} mb-9 leading-7  text-left text-base max-md:text-sm`}><p>1.{t('roadmap.q1241')}</p><p>2.{t('roadmap.q1242')}</p></div> },
        { title: <div onClick={() => { setCurrentQuarter(1) }} className={`text-2xl mb-7 max-md:text-base ${currentQuarter === 1 ? 'text-white' : ''}  `}>Q2 2024</div>, description: <div onClick={() => { setCurrentQuarter(1) }} className={` ${currentQuarter === 1 ? 'text-white' : ''} mb-9 leading-7  text-left text-base max-md:text-sm`}><p>{t('roadmap.q2241')}</p></div> },
        { title: <div onClick={() => { setCurrentQuarter(2) }} className={`text-2xl mb-7 max-md:text-base ${currentQuarter === 2 ? 'text-white' : ''}  `}>Q3 2024</div>, description: <div onClick={() => { setCurrentQuarter(2) }} className={` ${currentQuarter === 2 ? 'text-white' : ''} mb-9 leading-7  text-left text-base max-md:text-sm`}><p>1.{t('roadmap.q3241')}</p><p>2.{t('roadmap.q3242')}</p><p>3.{t('roadmap.q3243')}</p><p>4.{t('roadmap.q3244')}</p><p>5.{t('roadmap.q3245')}</p></div> },
        { title: <div onClick={() => { setCurrentQuarter(3) }} className={`text-2xl mb-7 max-md:text-base ${currentQuarter === 3 ? 'text-white' : ''}  `}>Q4 2024</div>, description: <div onClick={() => { setCurrentQuarter(3) }} className={` ${currentQuarter === 3 ? 'text-white' : ''} mb-9 leading-7  text-left text-base max-md:text-sm`}><p>1.{t('roadmap.q4241')}</p><p>2.{t('roadmap.q4242')}</p><p>3.{t('roadmap.q4243')}</p><p>4.{t('roadmap.q4244')}</p><p>5.{t('roadmap.q4245')}</p></div> },
        { title: <div onClick={() => { setCurrentQuarter(4) }} className={`text-2xl mb-7 max-md:text-base ${currentQuarter === 4 ? 'text-white' : ''}  `}>Q1 2025   </div>, description: <div onClick={() => { setCurrentQuarter(4) }} className={` ${currentQuarter === 4 ? 'text-white' : ''} mb-9 leading-7   text-left text-base max-md:text-sm`}><p>1.{t('roadmap.q1251')}</p><p>2.{t('roadmap.q1252')}</p><p>3.{t('roadmap.q1253')}</p><p>4.{t('roadmap.q1254')}</p></div> },
    ]
    const customDot: StepsProps['progressDot'] = (dot, { status, index }) => (
        <div onClick={() => { setCurrentQuarter(index) }} className={`yuan ${currentQuarter === index ? 'xzYuan' : ""}`} />
    );
    useEffect(() => {
        const time = getCurrentQuarter()
        setCurrentQuarter(time)
    }, [getCurrentQuarter])
    return (
        <div className='mt-34.5 mb-[400px] max-878:mt-9 max-878:mb-[153px] max-w-[1200px] m-auto'>
            <h1 className="social-text text-5xl font-bold text-center mb-6 max-878:text-2xl">Roadmap</h1>
            <div className="w-24 h-1 xian mx-auto max-878:h-1 max-878:w-17  " />
            <ConfigProvider
                theme={{
                    components: {
                        Steps: {
                            colorText: "#B6B6B6",
                            colorTextDescription: "#B6B6B6",
                            descriptionMaxWidth: 500,
                            colorPrimary: "#FFFFFF",
                        }
                    },
                }}
            >
                <div className="mt-[100px] w-[50%] mx-auto max-md:w-full max-md:px-5">
                    <Steps
                        className="block"
                        progressDot={customDot}
                        direction="vertical"
                        current={5}
                        labelPlacement={'vertical'}
                        items={data}
                    />
                </div>
            </ConfigProvider>
        </div >
    )
}

export default Roadmap
