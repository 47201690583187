import { useTranslation } from "react-i18next"


const HighlightsKey = () => {
    const { t } = useTranslation()
    const data = [
        { title: t('highlightsKey.title1'), text: t('highlightsKey.text1') },
        { title: t('highlightsKey.title2'), text: t('highlightsKey.text2') },
        { title: t('highlightsKey.title3'), text: t('highlightsKey.text3') },
        { title: t('highlightsKey.title4'), text: t('highlightsKey.text4') },
        { title: t('highlightsKey.title5'), text: t('highlightsKey.text5') },
        { title: t('highlightsKey.title6'), text: t('highlightsKey.text6') },
    ]
    return (
        <div className="mt-12.5 mx-10 max-878:mt-0  max-878:mx-6">
            <h1 className="social-text text-5xl font-bold text-center mb-6 max-878:text-2xl ">Highlights & Key</h1>
            <div className="w-24 h-1 xian mx-auto  mb-23 max-878:h-1 max-878:w-17 max-878:mb-11" />
            <div className=" flex flex-wrap items-center justify-between max-w-320 mx-auto max-1350:justify-around ">
                {data.map((item) => {
                    return <div key={item.title} className={`relative box-border w-99.75 h-115  px-7.5 border rounded-10.25 cursor-pointer   border-black31 py-10 bg-black13  mb-10 gradient-shadow max-878:h-86.5 max-878:w-85.75  max-878:px-4 `}>
                        <div className="overflow-y-auto w-full h-full ">
                            <div className="pb-10 flex items-center max-878:pb-7.75">
                                <div className=" w-1 h-7.5 shuXian mr-3.5 " />
                                <div className="text-white text-xl font-semibold max-878:text-lg">{item.title}</div>
                            </div>
                            <p className="text-gray64  text-lg font-light leading-8 max-878:leading-7 max-878:text-sm  w-ca">{item.text}</p>
                        </div>
                    </div>
                })}
            </div>
        </div>
    )
}

export default HighlightsKey