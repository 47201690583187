import HighlightsKey from "./components/highlightsKey"
import Questions from "./components/questions"
import Roadmap from "./components/roadmap"
import TitleCom from "./components/titleCom"


const Home = () => {
  return (
    <div className="overflow-hidden">
      <div className=" bg-bg_tow bg-no-repeat	bg-[length:390px_390px] bg-top relative	">
        <div className="bg-bg_four bg-cover bg-no-repeat  absolute w-[490px] h-[490px] left-[-250px] top-[-100px]" />
        <TitleCom />
      </div>
      <div className="relative">
        <div className="bg-bg_three w-[514px] h-[514px] bg-no-repeat bg-cover  absolute top-[-150px] right-[-306px]" />
        <HighlightsKey />
      </div>
      <Roadmap />
      <Questions />
    </div >
  )
}

export default Home