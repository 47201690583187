import './style/index.css'
import AppRouter from '@/router'
import { App, ConfigProvider } from 'antd';
// import "@/log/index";
function MyApp() {
  return (
    <div className="MyApp">

      <ConfigProvider

        theme={{
          token: {
            colorPrimary: "#2C363F",
          },
          components: {
            Notification: {
              colorBgElevated: 'rgb(19,47,55)', // 浮层容器背景色
              colorText: '#FFFFFF', // 最深的文本色
              colorTextHeading: '#FFFFFF',
              colorIcon: "#FFFFFF"
            },
          },
        }}
      >
        <App>
          <AppRouter />
        </App>

      </ConfigProvider>
    </div>
  );
}

export default MyApp;
