import twitterLogo from '@/image/twitterLogo.png'
import telegramLogo from '@/image/telegramLogo.png'
import githubLogo from '@/image/githubLogo.png'
const data = [
  { img: twitterLogo, title: 'Twitter', width: '18.59px' },
  {
    img: telegramLogo, title: 'Telegram', width: '18.16px'
  },
  { img: githubLogo, title: 'Github', width: '17.3px' }
]
const Foot = () => {
  return (
    <div className="box-border  border-t border-black31 pt-13.5 mb-131.75 flex items-center justify-center max-878:pt-6 max-878:mb-18.5">
      {data.map((item, index) => {
        return <div key={item.title} className={`flex  items-center  ${index === data.length - 1 ? '' : 'mr-14.75'} `}>
          <div className='w-12 h-12 bg-blackBg rounded-full flex justify-center items-center cursor-pointer hover:bg-violet9b max-878:w-9 max-878:h-9'>
            <img src={item.img} style={{ width: item.width }} alt="" />
          </div>
        </div>
      })}
    </div>
  )
}

export default Foot