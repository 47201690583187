import { Collapse, CollapseProps, ConfigProvider } from "antd"
import add from '@/image/add.png'
import { useTranslation } from "react-i18next";

const Questions = () => {
    const { t } = useTranslation()
    const itemsData: CollapseProps['items'] = [
        {
            key: '1',
            label: t('questions.title1'),
            children: <p style={{ color: ' rgba(255, 255, 255, 0.5)' }} className=" text-[18px] font-light leading-7 max-878:leading-5 max-878:text-xs">
                {t('questions.text1')}
            </p>,
            style: {
                marginBottom: '60px',
            },
        }, {
            key: '2',
            label: t('questions.title2'),
            children: <p style={{ color: ' rgba(255, 255, 255, 0.5)' }} className=" text-[18px] font-light leading-7 max-878:leading-5 max-878:text-xs">
                {t('questions.text2')}
            </p>,
            style: {
                marginBottom: '60px',
            },
        }, {
            key: '3',
            label: t('questions.title3'),
            children: <p style={{ color: ' rgba(255, 255, 255, 0.5)' }} className=" text-[18px] font-light leading-7 max-878:leading-5 max-878:text-xs">
                {t('questions.text3')}
            </p>,
            style: {
                marginBottom: '60px',
            },
        }, {
            key: '4',
            label: t('questions.title4'),
            children: <p style={{ color: ' rgba(255, 255, 255, 0.5)' }} className=" text-[18px] font-light leading-7 max-878:leading-5 max-878:text-xs">
                {t('questions.text4')}
            </p>,
            style: {
                marginBottom: '60px',
            },
        }, {
            key: '5',
            label: t('questions.title5'),
            children: <p style={{ color: ' rgba(255, 255, 255, 0.5)' }} className=" text-[18px] font-light leading-7 max-878:leading-5 max-878:text-xs">
                {t('questions.text5')}
            </p>,
            style: {
                marginBottom: '60px',
            },
        }, {
            key: '6',
            label: t('questions.title6'),
            children: <p style={{ color: ' rgba(255, 255, 255, 0.5)' }} className=" text-[18px] font-light leading-7 max-878:leading-5 max-878:text-xs">
                {t('questions.text6')}
            </p>,
            style: {
                marginBottom: '60px',
            },
        }, {
            key: '7',
            label: t('questions.title7'),
            children: <p style={{ color: ' rgba(255, 255, 255, 0.5)' }} className=" text-[18px] font-light leading-7 max-878:leading-5 max-878:text-xs">
                {t('questions.text7')}
            </p>,
        },
    ];
    return (
        <div className='max-w-[1227px] mx-auto flex justify-between  max-1350:flex-col max-1350:items-center max-878:mx-10 '>
            <div>
                <h1 className='text-5xl text-white pb-3 font-medium max-878:text-2xl'>Frequently Asked</h1>
                <h1 className='social-text text-5xl font-bold max-878:text-2xl'>Questions</h1>
            </div>
            <ConfigProvider
                theme={{
                    components: {
                        Collapse: {
                            colorTextHeading: '#FFF',
                            padding: 0,
                            paddingSM: 0,
                            contentPadding: 0,
                        }
                    },
                }}
            >
                <Collapse expandIconPosition={'end'}
                    ghost={true}
                    expandIcon={(PanelProps) => <div style={{ border: '1px solid rgba(255, 255, 255, 0.07)' }}
                        className={`mt-4 w-[42px] h-[42px] rounded-full ml-[88px]  flex items-center justify-center ${PanelProps.isActive ? 'rotate-45' : ''} max-878:mt-1 max-878:ml-3 max-878:w-8 max-878:h-8`}>
                        <img src={add} className="w-4 h-4  max-878:w-3 max-878:h-3" alt="" />
                    </div>}

                    className="text-[25px] w-[608px] mb-[129px] max-1350:mt-20 max-878:w-full max-878:text-base "
                    items={itemsData} bordered={false} />
            </ConfigProvider>
        </div>
    )
}

export default Questions