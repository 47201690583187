import { Collapse, CollapseProps, ConfigProvider, Drawer, Dropdown, DropdownProps, MenuProps } from "antd"
import topArrows from "@/image/topArrows.png"
import menuImg from "@/image/menuImg.png"
import close from "@/image/close.png"
import logo from "@/image/logo.png"
import { useTranslation } from "react-i18next";
import { useState } from "react";
import useLanguage from "@/store/useLanguage"
import useNotification from "@/hooks/useNotification"
const items: MenuProps['items'] = [
  {
    key: 'en',
    label: 'English',
  },
  {
    key: 'cn',
    label: '简体中文'
  }
];
const Header = () => {
  const { showNotification } = useNotification()
  const { setLanguage } = useLanguage()
  const [open, setOpen] = useState(false);
  const { i18n: { language, changeLanguage } } = useTranslation();
  const [openW, setOpenW] = useState(false);
  const { t } = useTranslation()
  const onClick: MenuProps['onClick'] = (e) => {
    changeLanguage(e.key)
    setLanguage(e.key)
    setOpen(false);
  };
  const handleOpenChange: DropdownProps['onOpenChange'] = (nextOpen, info) => {
    if (info.source === 'trigger' || nextOpen) {
      setOpen(nextOpen);
    }
  };
  const itemsData: CollapseProps['items'] = [
    {
      key: '1',
      label: language === 'cn' ? '简体中文' : 'English',
      children: <div className="text-[#AAA9AC]">
        <p onClick={() => { changeLanguage('en'); setOpenW(false) }} className="pt-5.625">English</p>
        <p onClick={() => { changeLanguage('cn'); setOpenW(false) }} className="pt-5.625">简体中文</p>
      </div>,
    },
  ];
  return (
    <div className="relative z-50 pl-23.25 pr-19.5 pt-7.5 flex items-center justify-between max-878:px-6 max-878:pt-6.5 ">
      <div className=" flex items-center">
        <img src={logo} className=" w-40.25 max-878:w-36.75" alt="" />
      </div>
      <ConfigProvider
        theme={{
          components: {
            Dropdown: {
              colorBgElevated: "#13111A",
              boxShadowSecondary: "0px 1.13px 48.39px 4.84px #272555",
              controlPaddingHorizontal: 71,
              paddingXXS: 10,
              paddingBlock: 13.5,
              fontSize: 16,
              controlItemBgActive: '#161616',
              colorText: "#FFFFFF",
              colorPrimary: "#9359F6",
              borderRadiusLG: 24,
              borderRadiusSM: 16,
              controlItemBgActiveHover: ''
            },
            Drawer: {
              colorBgElevated: '#13111A'
            },
            Collapse: {
              colorTextHeading: '#FFF',
              padding: 0,
              paddingSM: 0
            }
          },
        }}
      >
        <div className=" text-white flex items-center max-878:hidden">
          <div className="mr-14 cursor-pointer">White Paper</div>
          <div className="mr-14 cursor-pointer">Stake</div>

          <Dropdown menu={{
            items, selectable: true,
            defaultSelectedKeys: [language],
            onClick
          }} open={open}
            onOpenChange={handleOpenChange}

            placement="bottomRight" overlayStyle={{ top: '80px' }} trigger={['click']}>
            <div className="mr-14 cursor-pointer flex items-center">
              <span className="mr-1.5">{language === 'cn' ? '简体中文' : 'English'}</span>
              <img src={topArrows} className={`w-6 h-6 transition-all	 ${open ? '' : 'rotate-180'}`} alt="" />
            </div>
          </Dropdown>

          <div className="box-border font-medium px-6.5 py-2  launchBorder cursor-pointer" onClick={() => { showNotification('info', { message: t('comingSoon') }) }}>Launch App</div>
        </div>
        <div className="hidden max-878:block">
          <img className="w-5" src={openW ? close : menuImg} onClick={() => setOpenW(!openW)} alt="" />
          <Drawer
            className="h-screen"
            zIndex={10}
            placement='top'
            maskClosable={true}
            closable={false}
            open={openW}
            onClose={() => setOpenW(false)}
          >
            <div className="text-white text-base pt-25.5">
              <p className="pb-9" >White Paper</p>
              <p className="pb-9" >Stake</p>
              <Collapse expandIconPosition={'end'}
                className="text-base"
                items={itemsData} bordered={false} />
            </div>
          </Drawer>
        </div>
      </ConfigProvider>
    </div >
  )
}

export default Header