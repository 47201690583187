import useNotification from '@/hooks/useNotification'
import codeImg from '@/image/codeImg.png'
import { useTranslation } from 'react-i18next'
const TitleCom = () => {
    const { t } = useTranslation()
    const { showNotification } = useNotification()
    return (
        <>
            <div className="text-center pt-36.5 max-878:mx-4 max-878:pt-17.75">
                <h1 className="text-white text-5xl font-bold max-878:text-4xl">The Full-Chain Customized</h1>
                <h2 className="text-5xl font-bold social-text pt-7.5 max-878:text-4xl max-878:pt-4">social protocol</h2>
                <p className="text-grayE55  text-xl max-w-209.25 m-auto pt-9 font-extralight leading-9 max-878:text-sm">Social X is a customized full-chain social protocol, making the customization of Dapp in the Web3 social field no longer a problem</p>
                <div onClick={() => { showNotification('info', { message: t('comingSoon') }) }} className="mt-14 rounded-12.5 bgBtn text-white w-72.25 h-18.25 text-xl flex items-center  justify-center  m-auto cursor-pointer max-878:mt-6.5 max-878:text-sm max-878:w-32.5  max-878:h-11">Launch App</div>
            </div>
            <div className="max-w-297.25 flex justify-between items-center ml-[12.22222%] mr-[5.208333%] mt-2 1440-plus:mx-auto  max-1100:flex-col  max-1100:mx-0 max-1100:mt-20 max-878:mt-9">
                <div className=''>
                    <h1 className='text-white text-4xl font-semibold max-878:text-2xl max-878:text-center'>The True Meaning Of Socialfi</h1>
                    <p className='text-grayE89 text-xl pt-8 font-light max-878:text-sm'>Use only the most concise <span className='text-white font-bold'>20 lines</span> of code to explain</p>
                </div>
                <img src={codeImg} alt="" className='w-107.25 h-136.3825 max-878:w-85.75 max-878:h-78.75' />
            </div>
        </>
    )
}

export default TitleCom